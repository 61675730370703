<template>
  <div class="home">
    <EpuData :patients="patients" />
  </div>
</template>

<script>

import EpuData from '../components/EpuData.vue';
import { onSnapshot, alluser } from '@/Auth/index';
export default {
  name: "EPU",
  data: () => ({
    patients: []
  }),
  created(){
    this.getAllPatients()
  },
  components: {
    EpuData,
  },
  methods: {
    async getAllPatients() {
      onSnapshot(alluser("patients"), (snapshot) => {
        this.patients = []
        this.patsList = []
        snapshot.forEach((doc) => {
          this.patients.push({ ...doc.data(), id: doc.id });

        });
      })
    }
  }
};
</script>